import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';

import {
  IGetBannerEnrichmentsResponse200,
  IGetBannerEnrichmentsResponse400,
  TGetBannerEnrichmentsRequest,
  TGetBannerEnrichmentsResponse_1,
  createGetBannerEnrichmentsModel,
  fetchGetBannerEnrichments,
} from 'shared/repositories/ad-banner/v1/get-banner-enrichments';

export async function fetchGetNewbuildingsInfoForTgbService(
  applicationContext: { logger: ILogger; httpApi: IHttpApi },
  parameters: TGetBannerEnrichmentsRequest,
): Promise<TGetBannerEnrichmentsResponse_1> {
  const { httpApi, logger } = applicationContext;

  const domain = 'src/shared/services/newbuildingSearch/fetchGetNewbuildingsInfoForTgbService.ts';

  return fetchGetBannerEnrichments<IGetBannerEnrichmentsResponse200, IGetBannerEnrichmentsResponse400>({
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400: {
          const httpBadStatusCodeError = new HttpBadStatusCodeError({
            message: `Ошибка в запросе: ${result.response?.message}`,
            domain,
            statusCode,
            details: {
              model: JSON.stringify(createGetBannerEnrichmentsModel(parameters)),
            },
          });

          logger.warning(httpBadStatusCodeError.message, {
            domain: httpBadStatusCodeError.domain,
            details: httpBadStatusCodeError.printDetails(),
          });

          throw httpBadStatusCodeError;
        }
        default: {
          const httpBadStatusCodeError = new HttpBadStatusCodeError({
            message: `Неизвестный статус ответа: ${statusCode}`,
            domain,
            statusCode,
            details: {
              model: JSON.stringify(createGetBannerEnrichmentsModel(parameters)),
            },
          });

          logger.error(httpBadStatusCodeError.message, {
            domain: httpBadStatusCodeError.domain,
            details: httpBadStatusCodeError.printDetails(),
          });

          throw httpBadStatusCodeError;
        }
      }
    },
    error => {
      const unknownError = new UnknownError({
        message: 'Неизвестная ошибка в запросе',
        domain,
        details: {
          model: JSON.stringify(createGetBannerEnrichmentsModel(parameters)),
        },
      });

      logger.error(error, {
        domain: unknownError.domain,
        message: unknownError.message,
        details: unknownError.printDetails(),
      });

      throw unknownError;
    },
  );
}
