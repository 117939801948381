import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import { useEffect, useState } from 'react';

import { IBannerEnrichments } from 'shared/repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';
import {
  EDealType,
  EObjectType,
} from 'shared/repositories/ad-banner/entities/get_banner_enrichments_v1/GetBannerEnrichmentsV1Request';
import { useApplicationContext } from 'shared/serp/containers/ApplicationContext';
import { fetchGetNewbuildingsInfoForTgbService } from 'shared/services/newbuildingSearch';

const infoForTgbMap = new Map<number, IBannerEnrichments>();

export function useNewbuidingInfoForTgb({
  newBuildingId,
  newBuildingIdIsUsed,
  objectId,
  objectType,
  dealType,
  useServerEnrichment,
}: TTgbNativedescGeneralContext): null | IBannerEnrichments {
  const { httpApi, logger } = useApplicationContext();

  const newBuildingIdInt = newBuildingId ? parseInt(newBuildingId, 10) : null;
  const objectIdInt = objectId ? parseInt(objectId, 10) : newBuildingIdInt;

  /**
   * @description Только для значений newBuildingIdIsUsed === '1' и useServerEnrichment === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1' || useServerEnrichment === '1';

  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = useState<null | IBannerEnrichments>(() => {
    /**
     * @description если уже много данных в кеше, то чистим его, по времени нет особого смыла как то кешировать,
     * так же как и какие данные оставлять в кеше
     */
    if (infoForTgbMap.size > 100) {
      infoForTgbMap.clear();

      return null;
    }

    let newbuidingInfoForTgb: null | IBannerEnrichments = null;

    if (newBuildingIdInt && isEnrichmentFromBackendEnabled) {
      newbuidingInfoForTgb = infoForTgbMap.get(newBuildingIdInt) ?? null;
    }

    return newbuidingInfoForTgb;
  });

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && objectIdInt && !newbuidingInfoForTgb) {
      fetchGetNewbuildingsInfoForTgbService(
        { logger, httpApi },
        { objectId: objectIdInt, dealType: dealType as EDealType, objectType: objectType as EObjectType },
      ).then(data => {
        if (!data.enrichments) {
          return;
        }

        infoForTgbMap.set(objectIdInt, data.enrichments);

        setNewbuidingInfoForTgb(data.enrichments);
      });
    }
    /**
     * @description изменение данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return newbuidingInfoForTgb;
}
